@mixin fontFace($family, $src, $style: normal, $weight: normal)
{
  @font-face
  {
    font-family: $family;
    src: url('#{$src}.woff2') format('woff2'),
         url('#{$src}.woff') format('woff');
    font-style: $style;
    font-weight: $weight;
  }
}

